<template>
  <div class="mt-5">
    <br />
    <br />
    <br />
    <div class="row">
      <div class="col-12 mb-2">
        <button @click="refresh()" class="btn ms-auto btn-info">
          {{ $t("dashboard1") }}
        </button>
      </div>
    </div>

    <div class="row align-items-center text-secondary">
      <div class="col-6 col-lg-3 p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-3">
            <i
              class="bi bi-cart3"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-9">
            <div class="text-center">
              {{ $t("dashboard2") }}
            </div>
            <div class="text-center">
              <span v-if="totalTodays.sales" class="text-primary">
                {{ totalTodays.sales.priceTotalTTCSale }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-lg-3 p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-3">
            <i
              class="bi bi-window-x"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-9">
            <div class="text-center">
              {{ $t("dashboard3") }}
            </div>
            <div class="text-center">
              <span v-if="totalTodays.sales" class="text-success">
                {{ totalTodays.sales.totalPaidSale }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-lg-3 p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-3">
            <i
              class="bi bi-window-dash"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-9">
            <div class="text-center">
              {{ $t("dashboard4") }}
            </div>
            <div class="text-center">
              <span v-if="totalTodays.sales" class="text-danger">
                {{ totalTodays.sales.totalUnpaidSale }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-lg-3 p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-3">
            <i
              class="bi bi-cash-stack"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-9">
            <div class="text-center">
              {{ $t("dashboard5") }}
            </div>
            <div class="text-center">
              <span v-if="totalTodays.sales" class="text-warning">
                {{ totalTodays.sales.totalPaidOldBillSale }} 
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <br /> -->
    <div class="row align-items-center text-secondary">
      <div class="col-6 col-lg-3 p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-3">
            <i
              class="bi bi-wallet"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-9">
            <div class="text-center">
              {{ $t("dashboard6") }}
            </div>
            <div class="text-center">
              <span v-if="totalTodays.sales" class="text-primary">
                {{ totalTodays.checkout.amountAtCheckout }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-lg-3 p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-3">
            <i
              class="bi bi-cash"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-9">
            <div class="text-center">
              {{ $t("dashboard7") }}
            </div>
            <div class="text-center">
              <span v-if="totalTodays.sales" class="text-success">  </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-lg-3 p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-3">
            <i
              class="bi bi-bank"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-9">
            <div class="text-center">
              {{ $t("dashboard8") }}
            </div>
            <div class="text-center">
              <span v-if="totalTodays.sales" class="text-danger">  </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 col-lg-3 p-3">
        <div class="row align-items-center py-1 border rounded bg-white">
          <div class="col-3">
            <i
              class="bi bi-cash-coin"
              :style="{
                fontSize: '35px !important',
              }"
            ></i>
          </div>
          <div class="col-9">
            <div class="text-center">
              {{ $t("dashboard9") }}
            </div>
            <div class="text-center">
              <span v-if="totalTodays.sales" class="text-warning">  </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div class="row align-items-stratch">
      <div class="col-12 col-xl-4 p-2" v-if="barTodaysChartData">
        <div class="rounded bg-white border">
          <BarChartSalesTodayVue
            :chart-data="barTodaysChartData"
          ></BarChartSalesTodayVue>
        </div>
      </div>

      <div class="col-12 col-xl-8 p-2">
        <div class="bg-white rounded border h-100 p-3">
          <div class="row align-items-center">
            <div class="col">
              <div class="row align-items-center">
                <div class="col"></div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col">Total</div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col">Payé</div>
              </div>
            </div>

            <div class="col">
              <div>Impayé</div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col">Règlement</div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row align-items-center">
            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  {{ $t("dashboard10") }}
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-info"> 0.00 </span>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-success"> + 0.00 </span>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-danger"> - 0.00 </span>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-warning"> + 0.00 </span>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="row align-items-center">
            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  {{ $t("dashboard11") }}
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-info"> 0.00 </span>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-success"> + 0.00 </span>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-danger"> - 0.00 </span>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-warning"> + 0.00 </span>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="row align-items-center">
            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  {{ $t("dashboard12") }}
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-info"> 0.00 </span>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-success"> + 0.00 </span>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-danger"> - 0.00 </span>
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="fw-bold text-warning"> + 0.00 </span>
                </div>
              </div>
            </div>
          </div>

          <br />
          <div class="row align-items-center">
            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  {{ $t("dashboard13") }}
                </div>
              </div>
            </div>

            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  {{ $t("dashboard13") }}
                </div>
                <div class="col-auto">
                  <span class="fw-bold"> 0.00 </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-12 col-xl-6">
        <line-chart
          :chart-data="lineChartData"
          :options="lineChartOptions"
        ></line-chart>
      </div> -->

      <!-- <div class="col-4">
        <scatter-chart
          :chart-data="scatterData"
          :options="scatterOptions"
        ></scatter-chart>
      </div> -->
    </div>
    <br />
    <br />

    <div class="row p-1">
      <div class="bg-white rounded border p-3">
        <div class="row">
          <div class="col text-center text-secondary">
            {{ $t("dashboard14") }}
          </div>
        </div>
        <br />
        <div
          v-for="(quantity, index) in quantityAnalytics"
          :key="index++"
          class="row"
        >
          <div class="col">{{ quantityreference }} {{ quantity.fullName }}</div>
          <div class="col-2">
            <span class="text-success">
              + {{ quantity.purchasedQuantity }}
            </span>
          </div>
          <div class="col-2">
            <span class="text-danger"> - {{ quantity.soldQuantity }} </span>
          </div>
          <div class="col-2">
            <span class="text-primary">
              {{ quantity.stockQuantity }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BarChartVue from "../components/chart/BarChart.vue";
import BarChartSalesTodayVue from "../components/chart/BarChartSalesToday.vue";
import LineChart from "../components/chart/LineChart.vue";
import ScatterChart from "../components/chart/ScatterChart.vue";
import moment from "moment";
export default {
  components: {
    BarChartVue,
    ScatterChart,
    LineChart,
    BarChartSalesTodayVue,
  },
  data() {
    return {
      scatterData: {
        datasets: [
          {
            label: "Scatter Data",
            data: [
              { x: 10, y: 20 },
              { x: 5, y: 25 },
              { x: 15, y: 10 },
              // Add more data points as needed
            ],
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
            showLine: true,
          },
        ],
      },
      scatterOptions: {
        scales: {
          x: {
            type: "linear",
            position: "bottom",
          },
          y: {
            type: "linear",
            position: "left",
          },
        },
        // Add more chart options as needed
      },

      lineChartData: {
        labels: ["Total Ventes", "Payé", "Impayé", "Règlement "],
        datasets: [
          {
            label: "My Line Chart",
            data: [10, 20, 30, 25],
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      },
      lineChartOptions: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        height: 300,
      },
    };
  },
  computed: {
    ...mapGetters("statistic", {
      totalTodays: "getTotalTodays",
      barTodaysChartData: "getBarTodaysChartData",
      quantityAnalytics: "getQuantityAnalytics",
    }),
  },
  mounted() {
    this.$store.dispatch("statistic/fetchTotalTodays");
    this.$store.dispatch(
      "statistic/fetchQuantityAnalytics",
      moment(new Date()).format("YYYY-MM-DD")
    );
  },
  methods: {
    refresh() {
      this.$store.dispatch("statistic/fetchTotalTodays");
    },
  },
};
</script>
